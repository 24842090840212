import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import configureStore from "./store/configureStore";
import * as serviceWorker from "./serviceWorker";

import "@govtechsg/sgds/css/sgds.css"
import 'bootstrap-icons/font/bootstrap-icons.css';

import App from "./App";

const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
