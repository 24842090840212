// Show and hide activity spinner
export const UI_START_LOADING = "UI_START_LOADING";
export const UI_STOP_LOADING = "UI_STOP_LOADING";

// Update Theme Color
export const UPDATE_THEME_PRIMARY_COLOR = "UPDATE_THEME_PRIMARY_COLOR";
export const UPDATE_THEME_SECONDARY_COLOR = "UPDATE_THEME_SECONDARY_COLOR";
export const UPDATE_INFO_COLOR = "UPDATE_INFO_COLOR";
export const UPDATE_SUCCESS_COLOR = "UPDATE_SUCCESS_COLOR";
export const UPDATE_DANGER_COLOR = "UPDATE_DANGER_COLOR";
export const UPDATE_WARNING_COLOR = "UPDATE_WARNING_COLOR";
