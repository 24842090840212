const cssPresets = {
  themePresets: {
    primaryColor: "#5925dc",
    secondaryColor: "#1f69ff",
    infoColor: "#0f71bb",
    successColor: "#0a8217",
    dangerColor: "#d7260f",
    warningColor: "#f79009"
  }
};

export default cssPresets;
